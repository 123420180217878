import React from 'react';
import { render } from 'react-dom'
import './styles/index.scss';
import App from './containers/App';
import { BrowserRouter } from 'react-router-dom'
import { LocalizeProvider } from "react-localize-redux";
import * as serviceWorker from './serviceWorker';

render((
	<LocalizeProvider>
	  <BrowserRouter>
	    <App />
	  </BrowserRouter>
  </LocalizeProvider>
), document.getElementById('root'));

serviceWorker.unregister();
