import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

//import {isMobile} from 'react-device-detect';
import '../styles/App.scss';
import getRoutes from '../config/routes';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "../translations/global.json";

class App extends Component {
  constructor(props){
    super(props);
    this.props.initialize({
      languages: [
        { name: "Swedish", code: "sv" },
        { name: "English", code: "en" }
      ],
      translation: globalTranslations,
        options: { renderToStaticMarkup }
    });
    this.state = {}
  }

  render() {
    const elements = getRoutes();

    return (
      <div className="App">
        {elements}
      </div>
    );
  }
}

export default withLocalize(withRouter(App));