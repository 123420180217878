import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux';
import Masonry from 'react-masonry-component';
//import { Translate, setActiveLanguage} from "react-localize-redux";
//import {isMobile} from 'react-device-detect';

import logo from '../assets/imgs/logo.jpg';

class Home extends Component {
	constructor(props){
		super(props);
		this.state={
			pics:[]
		}
		this.host = window.location.hostname;
		if (this.host === 'localhost') {
			this.host = 'palmworkshop.se';
		}
		this.options = {
      gutter: 0,
      isFitWidth: true
    }
		
	}
	componentDidMount(){
		const request = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
				Expires: '0',
			},
		};
		let url = window.location.protocol+'//'+this.host +'/api/inst/getjson';
		fetch(url, request)
			.then(result => {
				return result.json();
			})
			.then(result => {
				const response = result;
				if(result.data){
					//console.log(response.data.data)
					this.setState({pics:response.data.data})
				}
			})
			.catch(error => {
				console.log("error", error);
			});
	}
	render(){
		let children = [];
		this.state.pics.map(((pic,index) =>{
			children.push(<div key={index}><img src={pic.images.low_resolution.url} /></div>);
		}))
		return(
			<div className="">
				<img src={logo} className="logo" alt="logo"/>
				<div className="w-100">
					<Masonry
						options={this.options}
						className={'masonry'}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}
          >
            {children}
          </Masonry>
				</div>
			</div>
		)
	}
}

export default withLocalize(withRouter(Home));