import React from 'react';
import { Route } from 'react-router-dom'
import Home from '../containers/Home';
 
const getRoutes = () => {
	return [
		<Route
			exact
			key="start"  
			path="/"
			render={() => {
				return <Home/>;
			}}
		/>,
	];
};
export default getRoutes;